var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',[_c('b-navbar',{attrs:{"toggleable":"lg","type":"dark","variant":"dark"}},[_c('b-navbar-brand',{attrs:{"to":"/"}},[_vm._v("BI")]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[(_vm.userInfo.isAdmin || _vm.userInfo.roles.includes('BotsAdmin'))?_c('b-nav-item',{attrs:{"to":{ name: 'new-intents' }}},[_vm._v("Bots ")]):_vm._e(),(
              _vm.userInfo.isAdmin || _vm.userInfo.roles.includes('SkypeBotsAdmin')
            )?_c('b-nav-item',{attrs:{"to":{ name: 'anti-spam' }}},[_vm._v("Anti Spam ")]):_vm._e(),(
              _vm.userInfo.isAdmin ||
              _vm.userInfo.roles.includes('ChatHistoriesViewer')
            )?_c('b-nav-item',{attrs:{"to":{ name: 'chat-history-summary' }}},[_vm._v("Report ")]):_vm._e(),(_vm.userInfo.isAdmin || _vm.userInfo.roles.includes('UsersAdmin'))?_c('b-nav-item',{attrs:{"to":{ name: 'users-management' }}},[_vm._v("Settings ")]):_vm._e(),(_vm.userInfo.isAdmin)?_c('b-nav-item',{attrs:{"to":{ name: 'audits' }}},[_vm._v("Admin ")]):_vm._e(),(_vm.userInfo)?_c('b-nav-item-dropdown',{staticClass:"border border-secondary rounded ml-5",attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-person-fill',{staticClass:"mr-2"}),_c('em',[_vm._v(_vm._s(_vm.userInfo.name))])]},proxy:true}],null,false,953344418)},[_c('b-dropdown-item',{attrs:{"href":"/identity/account/manage"}},[_vm._v("Manage ")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":_vm.Logout}},[_c('span',[_vm._v("Logout")])])],1):_c('b-nav-item',{attrs:{"href":"/identity/account/login"}},[_vm._v("Login")])],1)],1)],1),_c('b-row',{staticClass:"mx-0"},[(_vm.moduleLinks.length > 0)?_c('b-col',{staticClass:"sidenav__custom px-0",attrs:{"cols":"3","xl":"2","lg":"3"}},[_c('section',{staticClass:"p-3"},[_c('span',{staticClass:"text-uppercase font-weight-bolder"},[_vm._v(_vm._s(_vm.currentModule))])]),_c('b-nav',{attrs:{"vertical":""}},_vm._l((_vm.moduleLinks),function(link,index){return _c('div',{key:index},[(link.routeName)?_c('b-nav-item',{staticClass:"nav-item__custom",class:{
                'nav-item__custom__active':
                  _vm.currentRouteName === link.routeName,
              },attrs:{"to":{ name: link.routeName }}},[_vm._v(_vm._s(link.title)+" ")]):_c('b-nav-item',{staticClass:"nav-item__custom",attrs:{"href":link.href}},[_vm._v(_vm._s(link.title)+" ")])],1)}),0)],1):_vm._e(),_c('b-col',{staticClass:"px-5 py-3 content__custom"},[_c('router-view'),_c('section',{staticClass:"p-5"})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }