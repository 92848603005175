<template>
  <div>
    <b-link
      v-if="userInfo.isAdmin || userInfo.roles.includes('BotsAdmin')"
      class="btn btn-lg btn-primary module__link"
      style="width: 180px; margin: 1rem 0.5rem"
      :to="{ name: 'new-intents' }"
      >Bots
    </b-link>
    <b-link
      v-if="userInfo.isAdmin || userInfo.roles.includes('SkypeBotsAdmin')"
      class="btn btn-lg btn-primary module__link"
      style="width: 180px; margin: 1rem 0.5rem"
      :to="{ name: 'anti-spam' }"
      >Anti Spam
    </b-link>
    <b-link
      v-if="userInfo.isAdmin || userInfo.roles.includes('ChatHistoriesViewer')"
      class="btn btn-lg btn-primary module__link"
      style="width: 180px; margin: 1rem 0.5rem"
      :to="{ name: 'chat-history-summary' }"
      >Report
    </b-link>
    <b-link
      v-if="userInfo.isAdmin || userInfo.roles.includes('UsersAdmin')"
      class="btn btn-lg btn-primary module__link"
      style="width: 180px; margin: 1rem 0.5rem"
      :to="{ name: 'users-management' }"
      >Settings
    </b-link>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  methods: {},
};
</script>
