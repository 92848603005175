<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand to="/">BI</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item
              v-if="userInfo.isAdmin || userInfo.roles.includes('BotsAdmin')"
              :to="{ name: 'new-intents' }"
              >Bots
            </b-nav-item>
            <b-nav-item
              v-if="
                userInfo.isAdmin || userInfo.roles.includes('SkypeBotsAdmin')
              "
              :to="{ name: 'anti-spam' }"
              >Anti Spam
            </b-nav-item>
            <b-nav-item
              v-if="
                userInfo.isAdmin ||
                userInfo.roles.includes('ChatHistoriesViewer')
              "
              :to="{ name: 'chat-history-summary' }"
              >Report
            </b-nav-item>
            <b-nav-item
              v-if="userInfo.isAdmin || userInfo.roles.includes('UsersAdmin')"
              :to="{ name: 'users-management' }"
              >Settings
            </b-nav-item>
            <b-nav-item v-if="userInfo.isAdmin" :to="{ name: 'audits' }"
              >Admin
            </b-nav-item>
            <b-nav-item-dropdown
              class="border border-secondary rounded ml-5"
              v-if="userInfo"
              right
            >
              <!-- Using 'button-content' slot -->
              <template v-slot:button-content>
                <b-icon-person-fill class="mr-2" />
                <em>{{ userInfo.name }}</em>
              </template>
              <b-dropdown-item href="/identity/account/manage"
                >Manage
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="Logout">
                <span>Logout</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item v-else href="/identity/account/login">Login</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <b-row class="mx-0">
        <b-col
          v-if="moduleLinks.length > 0"
          cols="3"
          xl="2"
          lg="3"
          class="sidenav__custom px-0"
        >
          <section class="p-3">
            <span class="text-uppercase font-weight-bolder">{{
              currentModule
            }}</span>
          </section>
          <b-nav vertical>
            <div v-for="(link, index) in moduleLinks" :key="index">
              <b-nav-item
                v-if="link.routeName"
                class="nav-item__custom"
                :class="{
                  'nav-item__custom__active':
                    currentRouteName === link.routeName,
                }"
                :to="{ name: link.routeName }"
                >{{ link.title }}
              </b-nav-item>
              <b-nav-item v-else class="nav-item__custom" :href="link.href"
                >{{ link.title }}
              </b-nav-item>
            </div>
          </b-nav>
        </b-col>
        <b-col class="px-5 py-3 content__custom">
          <router-view />
          <!--padding for footer-->
          <section class="p-5"></section>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'app',
  data() {
    return {
      links: [
        { title: 'Bots', routeName: 'bots', module: 'bots' },
        { title: 'Bot Settings', routeName: 'bot-settings', module: 'bots' },
        {
          title: 'Client UI Settings',
          routeName: 'client-settings',
          module: 'bots',
        },
        {
          title: 'System Messages',
          routeName: 'system-messages',
          module: 'bots',
        },
        { title: 'Chat Rating', routeName: 'chat-rating', module: 'bots' },
        { title: 'Pre Chat', routeName: 'pre-chat', module: 'bots' },
        { title: 'Webhooks', routeName: 'webhooks', module: 'bots' },
        {
          title: 'Intents',
          routeName: 'new-intents',
          module: 'bots',
        },
        { title: 'Chat Filters', routeName: 'chat-filters', module: 'bots' },
        { title: 'Train Chat Bot', routeName: 'train-bot', module: 'bots' },
        { title: 'Audits', routeName: 'bot-audits', module: 'bots' },
        { title: 'Intents (old)', href: '/intents', module: 'bots' },

        { title: 'Active Chats', routeName: 'active-chats', module: 'report' },
        { title: 'Chat History', routeName: 'chat-history', module: 'report' },
        {
          title: 'Chat History Summary',
          routeName: 'chat-history-summary',
          module: 'report',
        },
        {
          title: 'Chat Rating Report',
          routeName: 'chat-rating-report',
          module: 'report',
        },
        { title: 'Chat Report', routeName: 'chat-report', module: 'report' },
        {
          title: 'Intent Summary',
          routeName: 'intent-summary',
          module: 'report',
        },
        {
          title: 'API Usages',
          routeName: 'api-usages',
          module: 'report',
        },

        { title: 'Audits', routeName: 'audits', module: 'admin' },
        {
          title: 'Application Configs',
          routeName: 'application-configs',
          module: 'admin',
        },

        {
          title: 'Users Management',
          routeName: 'users-management',
          module: 'settings',
        },
        { title: 'Trusted IPs', routeName: 'trusted-ip', module: 'settings' },
        {
          title: 'Blacklist Management',
          routeName: 'anti-spam',
          module: 'skype bots',
        },
      ],
    };
  },
  computed: {
    isPlainPage() {
      return this.$route ? this.$route.meta.isPlainPage : true;
    },
    moduleLinks() {
      return this.links.filter((link) => link.module === this.currentModule);
    },
    currentRouteName() {
      return this.$route.name;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    currentModule() {
      return this.$store.state.currentModule;
    },
  },
  methods: {
    Logout() {
      this.$store.commit('resetStates');
      axios.post('/identity/account/logout').then(function () {
        window.location.href = '/identity/account/login?ReturnUrl=%2F';
      });
    },
  },
};
</script>

<style>
body {
  background-color: #fefefe;
}

#app {
  height: 100vh;
  overflow-y: hidden;
}

.sidenav__custom {
  background-color: #f1f1f1;
  height: 100vh;
  overflow-y: auto;
}

.content__custom {
  height: 100vh;
  overflow: auto;
}

.nav-item__custom a {
  background-color: #f1f1f1;
  color: #212529 !important;
}

.nav-item__custom__active a {
  background-color: #fefefe;
}

.nav-item__custom:hover a {
  background-color: #fefefe;
  font-weight: bold;
}
</style>
